import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/srinidhi.jpg';

const AdvocateSrinidhiDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Ms. B. Srinidhi completed her B. B. A LLB (Hons.) from ICFAI LAW
                SCHOOL, Hyderabad in the year 2023. She is interested in the
                areas of Civil laws and arbitration. She enrolled as an Advocate
                in the year 2023 and joined Avadhani and Associates as a Trainee
                Advocate in the month of September 2023.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>B. Srinidhi. BBA LLB (Hons.)</h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateSrinidhiDetails;
