import AdvocateSrinidhiDetails from '../../components/about/advocates-details/srinidhi';
import Footer from '../../components/common/footer';
import Layout from '../../components/common/layout';
import NavigationMenu from '../../components/common/navigationMenu';
import PageHeader from '../../components/common/pageHeader';
import React from 'react';

const AdvocateSrinidhiDetailsPage = () => {
  return (
    <Layout pageTitle='Avadhani & Associates | Srinidhi'>
      <NavigationMenu active='about' />
      <PageHeader
        title='Srinidhi'
        subMenu='Advocates'
        backLink='/about#team-section'
      />
      <AdvocateSrinidhiDetails />
      <Footer />
    </Layout>
  );
};

export default AdvocateSrinidhiDetailsPage;
